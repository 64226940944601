@mixin font-icon {
	@font-face {
		font-family: "icon";
		src: url('../fonts/icons/icon.eot');
		src: url('../fonts/icons/icon.eot?#iefix') format('embedded-opentype'),
		url('../fonts/icons/icon.woff2') format('woff2'),
		url('../fonts/icons/icon.woff') format('woff'),
		url('../fonts/icons/icon.ttf') format('truetype'),
		url('../fonts/icons/icon.svg#icon') format('svg');
		font-display: block;
		unicode-range: U+EA01-EA40;
	}

	.be-icon:before {
		@include font-icon-style;
	}
	
	/* Glyphs list */
	
	.be-icon-arrow-bottom:before {
		content: '\ea01';
	}
	
	.be-icon-arrow-left:before {
		content: '\ea02';
	}
	
	.be-icon-arrow-right:before {
		content: '\ea03';
	}
	
	.be-icon-arrow-to-left:before {
		content: '\ea04';
	}
	
	.be-icon-arrow-to-right:before {
		content: '\ea05';
	}
	
	.be-icon-arrow-top:before {
		content: '\ea06';
	}
	
	.be-icon-bag:before {
		content: '\ea07';
	}
	
	.be-icon-calendar:before {
		content: '\ea08';
	}
	
	.be-icon-cart:before {
		content: '\ea09';
	}
	
	.be-icon-chat:before {
		content: '\ea0a';
	}
	
	.be-icon-check-circle:before {
		content: '\ea0b';
	}
	
	.be-icon-check:before {
		content: '\ea0c';
	}
	
	.be-icon-clock:before {
		content: '\ea0d';
	}
	
	.be-icon-close-circle:before {
		content: '\ea0e';
	}
	
	.be-icon-close:before {
		content: '\ea0f';
	}
	
	.be-icon-dm:before {
		content: '\ea10';
	}
	
	.be-icon-download:before {
		content: '\ea11';
	}
	
	.be-icon-email-border:before {
		content: '\ea12';
	}
	
	.be-icon-email-circle-thin:before {
		content: '\ea13';
	}
	
	.be-icon-email-circle:before {
		content: '\ea14';
	}
	
	.be-icon-email:before {
		content: '\ea15';
	}
	
	.be-icon-exclamation-circle:before {
		content: '\ea16';
	}
	
	.be-icon-exclamation:before {
		content: '\ea17';
	}
	
	.be-icon-eye-close:before {
		content: '\ea18';
	}
	
	.be-icon-eye:before {
		content: '\ea19';
	}
	
	.be-icon-fail:before {
		content: '\ea1a';
	}
	
	.be-icon-faq:before {
		content: '\ea1b';
	}
	
	.be-icon-fb:before {
		content: '\ea1c';
	}
	
	.be-icon-filter:before {
		content: '\ea1d';
	}
	
	.be-icon-global-circle:before {
		content: '\ea1e';
	}
	
	.be-icon-go-top:before {
		content: '\ea1f';
	}
	
	.be-icon-headphone:before {
		content: '\ea20';
	}
	
	.be-icon-heart-fill:before {
		content: '\ea21';
	}
	
	.be-icon-heart:before {
		content: '\ea22';
	}
	
	.be-icon-hint:before {
		content: '\ea23';
	}
	
	.be-icon-ig:before {
		content: '\ea24';
	}
	
	.be-icon-info-circle:before {
		content: '\ea25';
	}
	
	.be-icon-info:before {
		content: '\ea26';
	}
	
	.be-icon-link:before {
		content: '\ea27';
	}
	
	.be-icon-linkedin:before {
		content: '\ea28';
	}
	
	.be-icon-lock:before {
		content: '\ea29';
	}
	
	.be-icon-map-mark:before {
		content: '\ea2a';
	}
	
	.be-icon-mark:before {
		content: '\ea2b';
	}
	
	.be-icon-next:before {
		content: '\ea2c';
	}
	
	.be-icon-order:before {
		content: '\ea2d';
	}
	
	.be-icon-others:before {
		content: '\ea2e';
	}
	
	.be-icon-payment:before {
		content: '\ea2f';
	}
	
	.be-icon-play:before {
		content: '\ea30';
	}
	
	.be-icon-prev:before {
		content: '\ea31';
	}
	
	.be-icon-print-circle:before {
		content: '\ea32';
	}
	
	.be-icon-question:before {
		content: '\ea33';
	}
	
	.be-icon-search-b:before {
		content: '\ea34';
	}
	
	.be-icon-search:before {
		content: '\ea35';
	}
	
	.be-icon-share:before {
		content: '\ea36';
	}
	
	.be-icon-shield:before {
		content: '\ea37';
	}
	
	.be-icon-shipment:before {
		content: '\ea38';
	}
	
	.be-icon-tag:before {
		content: '\ea39';
	}
	
	.be-icon-tel-circle:before {
		content: '\ea3a';
	}
	
	.be-icon-tel:before {
		content: '\ea3b';
	}
	
	.be-icon-troubleshooting:before {
		content: '\ea3c';
	}
	
	.be-icon-twitter:before {
		content: '\ea3d';
	}
	
	.be-icon-user:before {
		content: '\ea3e';
	}
	
	.be-icon-warranty:before {
		content: '\ea3f';
	}
	
	.be-icon-yt:before {
		content: '\ea40';
	}
	
}

// <i>:before Style
@mixin font-icon-style {
	font-family: "icon";
	
	// Font smoothing. That was taken from TWBS
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	font-style: normal;
	font-weight: normal;

	// For safety - reset parent styles, that can break glyph codes
	font-variant: normal;
	text-transform: none;
	text-decoration: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	// only necessary if not using the private unicode range (firstGlyph option)
	speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;

	// fix buttons height, for twitter bootstrap
	line-height: 1em;

	// Animation center compensation - margins should be symmetric
	// remove if not needed
	margin-left: .2em;

	// you can be more comfortable with increased icons size
	// font-size: 120%;

	// Uncomment for 3D effect
	// text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3);
}

// @include font-icon;

// SASS變數使用範例
// .icon {
//     &:before{
// 		@include font-icon-SVG檔案名稱;
//     }
// }

// 內頁使用之Style
%font-icon-style {
	@include font-icon-style;
}


$font-icon-arrow-bottom: '\ea01';
@mixin font-icon-arrow-bottom($extendStyle: true) {
	content: '\ea01';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-left: '\ea02';
@mixin font-icon-arrow-left($extendStyle: true) {
	content: '\ea02';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-right: '\ea03';
@mixin font-icon-arrow-right($extendStyle: true) {
	content: '\ea03';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-to-left: '\ea04';
@mixin font-icon-arrow-to-left($extendStyle: true) {
	content: '\ea04';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-to-right: '\ea05';
@mixin font-icon-arrow-to-right($extendStyle: true) {
	content: '\ea05';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-arrow-top: '\ea06';
@mixin font-icon-arrow-top($extendStyle: true) {
	content: '\ea06';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-bag: '\ea07';
@mixin font-icon-bag($extendStyle: true) {
	content: '\ea07';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-calendar: '\ea08';
@mixin font-icon-calendar($extendStyle: true) {
	content: '\ea08';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-cart: '\ea09';
@mixin font-icon-cart($extendStyle: true) {
	content: '\ea09';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-chat: '\ea0a';
@mixin font-icon-chat($extendStyle: true) {
	content: '\ea0a';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-check-circle: '\ea0b';
@mixin font-icon-check-circle($extendStyle: true) {
	content: '\ea0b';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-check: '\ea0c';
@mixin font-icon-check($extendStyle: true) {
	content: '\ea0c';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-clock: '\ea0d';
@mixin font-icon-clock($extendStyle: true) {
	content: '\ea0d';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-close-circle: '\ea0e';
@mixin font-icon-close-circle($extendStyle: true) {
	content: '\ea0e';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-close: '\ea0f';
@mixin font-icon-close($extendStyle: true) {
	content: '\ea0f';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-dm: '\ea10';
@mixin font-icon-dm($extendStyle: true) {
	content: '\ea10';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-download: '\ea11';
@mixin font-icon-download($extendStyle: true) {
	content: '\ea11';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-email-border: '\ea12';
@mixin font-icon-email-border($extendStyle: true) {
	content: '\ea12';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-email-circle-thin: '\ea13';
@mixin font-icon-email-circle-thin($extendStyle: true) {
	content: '\ea13';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-email-circle: '\ea14';
@mixin font-icon-email-circle($extendStyle: true) {
	content: '\ea14';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-email: '\ea15';
@mixin font-icon-email($extendStyle: true) {
	content: '\ea15';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-exclamation-circle: '\ea16';
@mixin font-icon-exclamation-circle($extendStyle: true) {
	content: '\ea16';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-exclamation: '\ea17';
@mixin font-icon-exclamation($extendStyle: true) {
	content: '\ea17';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-eye-close: '\ea18';
@mixin font-icon-eye-close($extendStyle: true) {
	content: '\ea18';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-eye: '\ea19';
@mixin font-icon-eye($extendStyle: true) {
	content: '\ea19';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-fail: '\ea1a';
@mixin font-icon-fail($extendStyle: true) {
	content: '\ea1a';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-faq: '\ea1b';
@mixin font-icon-faq($extendStyle: true) {
	content: '\ea1b';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-fb: '\ea1c';
@mixin font-icon-fb($extendStyle: true) {
	content: '\ea1c';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-filter: '\ea1d';
@mixin font-icon-filter($extendStyle: true) {
	content: '\ea1d';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-global-circle: '\ea1e';
@mixin font-icon-global-circle($extendStyle: true) {
	content: '\ea1e';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-go-top: '\ea1f';
@mixin font-icon-go-top($extendStyle: true) {
	content: '\ea1f';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-headphone: '\ea20';
@mixin font-icon-headphone($extendStyle: true) {
	content: '\ea20';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-heart-fill: '\ea21';
@mixin font-icon-heart-fill($extendStyle: true) {
	content: '\ea21';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-heart: '\ea22';
@mixin font-icon-heart($extendStyle: true) {
	content: '\ea22';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-hint: '\ea23';
@mixin font-icon-hint($extendStyle: true) {
	content: '\ea23';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-ig: '\ea24';
@mixin font-icon-ig($extendStyle: true) {
	content: '\ea24';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-info-circle: '\ea25';
@mixin font-icon-info-circle($extendStyle: true) {
	content: '\ea25';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-info: '\ea26';
@mixin font-icon-info($extendStyle: true) {
	content: '\ea26';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-link: '\ea27';
@mixin font-icon-link($extendStyle: true) {
	content: '\ea27';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-linkedin: '\ea28';
@mixin font-icon-linkedin($extendStyle: true) {
	content: '\ea28';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-lock: '\ea29';
@mixin font-icon-lock($extendStyle: true) {
	content: '\ea29';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-map-mark: '\ea2a';
@mixin font-icon-map-mark($extendStyle: true) {
	content: '\ea2a';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-mark: '\ea2b';
@mixin font-icon-mark($extendStyle: true) {
	content: '\ea2b';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-next: '\ea2c';
@mixin font-icon-next($extendStyle: true) {
	content: '\ea2c';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-order: '\ea2d';
@mixin font-icon-order($extendStyle: true) {
	content: '\ea2d';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-others: '\ea2e';
@mixin font-icon-others($extendStyle: true) {
	content: '\ea2e';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-payment: '\ea2f';
@mixin font-icon-payment($extendStyle: true) {
	content: '\ea2f';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-play: '\ea30';
@mixin font-icon-play($extendStyle: true) {
	content: '\ea30';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-prev: '\ea31';
@mixin font-icon-prev($extendStyle: true) {
	content: '\ea31';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-print-circle: '\ea32';
@mixin font-icon-print-circle($extendStyle: true) {
	content: '\ea32';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-question: '\ea33';
@mixin font-icon-question($extendStyle: true) {
	content: '\ea33';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-search-b: '\ea34';
@mixin font-icon-search-b($extendStyle: true) {
	content: '\ea34';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-search: '\ea35';
@mixin font-icon-search($extendStyle: true) {
	content: '\ea35';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-share: '\ea36';
@mixin font-icon-share($extendStyle: true) {
	content: '\ea36';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-shield: '\ea37';
@mixin font-icon-shield($extendStyle: true) {
	content: '\ea37';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-shipment: '\ea38';
@mixin font-icon-shipment($extendStyle: true) {
	content: '\ea38';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-tag: '\ea39';
@mixin font-icon-tag($extendStyle: true) {
	content: '\ea39';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-tel-circle: '\ea3a';
@mixin font-icon-tel-circle($extendStyle: true) {
	content: '\ea3a';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-tel: '\ea3b';
@mixin font-icon-tel($extendStyle: true) {
	content: '\ea3b';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-troubleshooting: '\ea3c';
@mixin font-icon-troubleshooting($extendStyle: true) {
	content: '\ea3c';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-twitter: '\ea3d';
@mixin font-icon-twitter($extendStyle: true) {
	content: '\ea3d';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-user: '\ea3e';
@mixin font-icon-user($extendStyle: true) {
	content: '\ea3e';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-warranty: '\ea3f';
@mixin font-icon-warranty($extendStyle: true) {
	content: '\ea3f';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}

$font-icon-yt: '\ea40';
@mixin font-icon-yt($extendStyle: true) {
	content: '\ea40';
	
	@if $extendStyle==true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}


// 內頁使用之 Style v2
// 為了可以以變數帶入
@mixin font-icon-add($icon: '', $extendStyle: true) {
	
	@if $icon == arrow-bottom {
		content: '\ea01';
	}
	
	@if $icon == arrow-left {
		content: '\ea02';
	}
	
	@if $icon == arrow-right {
		content: '\ea03';
	}
	
	@if $icon == arrow-to-left {
		content: '\ea04';
	}
	
	@if $icon == arrow-to-right {
		content: '\ea05';
	}
	
	@if $icon == arrow-top {
		content: '\ea06';
	}
	
	@if $icon == bag {
		content: '\ea07';
	}
	
	@if $icon == calendar {
		content: '\ea08';
	}
	
	@if $icon == cart {
		content: '\ea09';
	}
	
	@if $icon == chat {
		content: '\ea0a';
	}
	
	@if $icon == check-circle {
		content: '\ea0b';
	}
	
	@if $icon == check {
		content: '\ea0c';
	}
	
	@if $icon == clock {
		content: '\ea0d';
	}
	
	@if $icon == close-circle {
		content: '\ea0e';
	}
	
	@if $icon == close {
		content: '\ea0f';
	}
	
	@if $icon == dm {
		content: '\ea10';
	}
	
	@if $icon == download {
		content: '\ea11';
	}
	
	@if $icon == email-border {
		content: '\ea12';
	}
	
	@if $icon == email-circle-thin {
		content: '\ea13';
	}
	
	@if $icon == email-circle {
		content: '\ea14';
	}
	
	@if $icon == email {
		content: '\ea15';
	}
	
	@if $icon == exclamation-circle {
		content: '\ea16';
	}
	
	@if $icon == exclamation {
		content: '\ea17';
	}
	
	@if $icon == eye-close {
		content: '\ea18';
	}
	
	@if $icon == eye {
		content: '\ea19';
	}
	
	@if $icon == fail {
		content: '\ea1a';
	}
	
	@if $icon == faq {
		content: '\ea1b';
	}
	
	@if $icon == fb {
		content: '\ea1c';
	}
	
	@if $icon == filter {
		content: '\ea1d';
	}
	
	@if $icon == global-circle {
		content: '\ea1e';
	}
	
	@if $icon == go-top {
		content: '\ea1f';
	}
	
	@if $icon == headphone {
		content: '\ea20';
	}
	
	@if $icon == heart-fill {
		content: '\ea21';
	}
	
	@if $icon == heart {
		content: '\ea22';
	}
	
	@if $icon == hint {
		content: '\ea23';
	}
	
	@if $icon == ig {
		content: '\ea24';
	}
	
	@if $icon == info-circle {
		content: '\ea25';
	}
	
	@if $icon == info {
		content: '\ea26';
	}
	
	@if $icon == link {
		content: '\ea27';
	}
	
	@if $icon == linkedin {
		content: '\ea28';
	}
	
	@if $icon == lock {
		content: '\ea29';
	}
	
	@if $icon == map-mark {
		content: '\ea2a';
	}
	
	@if $icon == mark {
		content: '\ea2b';
	}
	
	@if $icon == next {
		content: '\ea2c';
	}
	
	@if $icon == order {
		content: '\ea2d';
	}
	
	@if $icon == others {
		content: '\ea2e';
	}
	
	@if $icon == payment {
		content: '\ea2f';
	}
	
	@if $icon == play {
		content: '\ea30';
	}
	
	@if $icon == prev {
		content: '\ea31';
	}
	
	@if $icon == print-circle {
		content: '\ea32';
	}
	
	@if $icon == question {
		content: '\ea33';
	}
	
	@if $icon == search-b {
		content: '\ea34';
	}
	
	@if $icon == search {
		content: '\ea35';
	}
	
	@if $icon == share {
		content: '\ea36';
	}
	
	@if $icon == shield {
		content: '\ea37';
	}
	
	@if $icon == shipment {
		content: '\ea38';
	}
	
	@if $icon == tag {
		content: '\ea39';
	}
	
	@if $icon == tel-circle {
		content: '\ea3a';
	}
	
	@if $icon == tel {
		content: '\ea3b';
	}
	
	@if $icon == troubleshooting {
		content: '\ea3c';
	}
	
	@if $icon == twitter {
		content: '\ea3d';
	}
	
	@if $icon == user {
		content: '\ea3e';
	}
	
	@if $icon == warranty {
		content: '\ea3f';
	}
	
	@if $icon == yt {
		content: '\ea40';
	}
	

	@if $extendStyle == true {
		@extend %font-icon-style;
	}

	@else {
		font-family: "icon";
	}
}