.swiper-box {
	position: relative;
}

// 設定 Hover 特殊圖片
// 2021/09/29 客戶要求移除樣式
// 避免有些項目不使用，要使用需設定 class
// class 放在 .swiper-container 物件上
// .swiper-cursor {
// 	@include breakpoint-hover {
// 		// Init Swiper 後才有
// 		&.swiper-container-initialized {
// 			--cursor-img: url('../images/swiper_cursor.svg'); // SVG檔案當Cursor，一定要width, height
// 			--cursor-img-size: 61; // ★★★不需要單位
// 			--cursor-pos: calc(var(--cursor-img-size) / 2);

// 			cursor: var(--cursor-img) var(--cursor-pos) var(--cursor-pos), auto;

// 			a {
// 				--cursor-img: url('../images/swiper_cursor_hover.svg'); // SVG檔案當Cursor，一定要width, height
// 				--cursor-img-size: 61; // ★★★不需要單位

// 				cursor: var(--cursor-img) var(--cursor-pos) var(--cursor-pos), auto;
// 			}

// 			&.swiper-container-vertical {
// 				--cursor-img: url('../images/swiper_cursor_vertical.svg'); // SVG檔案當Cursor，一定要width, height
// 			}
// 		}
// 	}
// }

.swiper-slide {
	overflow: hidden;
}

// Effect: Fade
// ※配合內頁Product Content特效設定，fade 需要 !important則使用在既定項目
// .swiper-container-fade {
// 	.swiper-slide {
// 		// Fade Not Active
// 		&:not(.swiper-slide-active) {
// 			opacity: 0 !important;
// 		}
// 	}
// }

// Prev & Next
%swiper-button {
	--swiper-navigation-color: #b5b5b5;
	--swiper-navigation-size: #{rem-calc(14)};

	@include breakpoint(medium) {
		--swiper-navigation-size: #{rem-calc(22)};
	}

	@include breakpoint-hover {
		&:hover,
		&:focus {
			--swiper-navigation-color: #{$primary-color};
		}
	}

	// Circle Box 的 Icon 要額外增加 DOM <i> 放置
	&.circle-box {
		--swiper-navigation-size: var(--size);

		width: var(--swiper-navigation-size);

		&::after {
			// 還原 .circle-box 樣式
			content: ''; // 移除原本的icon
			margin-right: 0;
			margin-left: 0;
			width: calc(100% + var(--space) * 2); // 把原本的樣式
			letter-spacing: 0;
		}
	}
}

.swiper-button-next {
	@extend %swiper-button;

	&::after {
		@include font-icon-next;
	}
}

.swiper-button-prev {
	@extend %swiper-button;

	&::after {
		@include font-icon-prev;
	}
}

.swiper-button-next,
.swiper-button-prev {
	outline: 0;
	&:focus {
		outline: 0;
	}

	// &.swiper-button-disabled {
	// 	opacity: 0.1;
	// }
}

.swiper-pagination-bullet {
	&:focus {
		outline: none;
	}
}

// 控制區
.swiper-ctrl {
	$root: &;

	// Global 內部要加 .swiper-ctrl__cont
	// Pug 用 +swiper-ctrl-global
	&.global {
		--padding-x: #{rem-calc(40)};

		text-align: center;

		#{$root} {
			&__cont {
				position: relative;
				z-index: 1;
				display: inline-block;
				padding: 0 var(--padding-x);
			}
		}

		.swiper-pagination {
			position: static;
			font-size: rem-calc(12);
			color: #a0a0a0;

			@include breakpoint(medium) {
				font-size: rem-calc(24);
			}
		}

		.swiper-pagination-current {
			display: inline-block;
			min-width: (40 / 60 * 1em);
			color: $primary-color;
		}
	}
}

// 倒數 <svg>
.countdown-circle-svg {
	--outside-space: #{rem-calc(4)};

	position: absolute;
	top: 50%;
	left: 50%;
	width: calc(100% + var(--space) * 2 + var(--outside-space));
	height: calc(100% + var(--space) * 2 + var(--outside-space));
	transform: translate(-50%, -50%) rotate(-90deg);

	circle {
		stroke-width: 2px;
		stroke: var(--bg);
	}

	@include breakpoint(medium) {
		--outside-space: #{rem-calc(8)};
	}
}
