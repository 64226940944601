// Main Style
@import 'layout/variable_mixin_function.scss';

// --------------------------------------------------------

/* Vendor */
@import 'vendor/swiper/swiper';

// --------------------------------------------------------

/* Component */
@import './layout/dark-mode';

// --------------------------------------------------------

/* Container */

// 為了要讓客戶可以上滿版圖，調整的
.page-index {
	.main-wrapper {
		@include breakpoint(large) {
			padding-top: 0;
		}
	}

	.page-nav {
		--adjust: #{rem-calc(30)}; // 往上調多少 px (避免蓋到圖)

		position: absolute;
		top: calc(var(--hd-height-fixed) - var(--adjust));
		left: 0;
		width: 100%;
		text-transform: uppercase;

		@include breakpoint(xxlarge) {
			--adjust: #{rem-calc(20)}; // 往上調多少 px (避免蓋到圖)
		}

		@include breakpoint(xxlarge) {
			--adjust: 0px; // 往上調多少 px (避免蓋到圖)
		}
	}
}

// Banner
.index-banner {
	$root: &;

	position: relative;
	overflow: hidden;

	@include breakpoint(medium only) {
		min-height: rem-calc(700);
	}

	&::before {
		@include before-img-size(750, 960);

		@include breakpoint(large) {
			@include before-img-size(1920, 1200);
		}
	}

	// Background
	&::after {
		content: '';
		position: absolute;
		z-index: 50;
		bottom: -1px;
		left: 0;
		width: 100%;
		height: percentage(160 / 1200);
		background-image: $bg-gradient;
		mask-image: linear-gradient(to top, $black 0%, rgba($black, 0) 100%);
	}

	// === Banner Text ===
	&__cont {
		position: absolute;
		z-index: 10;
		top: 10%;
		left: 0;
		width: 100%;

		@include breakpoint(large) {
			top: calc(15.5% + var(--hd-height-fixed));
		}
	}

	// 文字區
	&__text-box {
		@include breakpoint(medium down) {
			&,
			&.text-right {
				text-align: center;
			}
		}

		@include breakpoint(large) {
			width: 60%;

			// 文字靠右
			&.text-right {
				margin-left: auto;
				text-align: right;
			}
		}
	}

	&__title {
		$min-width: 320px;
		$max-width: 1440px + 100 * 2;
		$min-font: 48px;
		$max-font: 112px;

		// Fluid Typography | 流體排版
		@include fluid-type($min-width, $max-width, $min-font, $max-font);

		overflow: hidden;
		margin-bottom: em-calc(16 / 48);
		margin-left: em-calc(10 / 112 * -1);
		max-height: (62.4 / 48 * 2 * 1em);
		font-weight: $font-weight-b;
		line-height: (62.4 / 48);
		text-transform: uppercase;
		color: $primary-color;
		text-shadow: 0 1px 1.68px rgba($black, 0.05);
		word-break: break-word;

		@include breakpoint(medium only) {
			font-size: vw-calc(48 / 375);
		}
	}

	// === Banner Img ===
	&__img-box {
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&::before {
			@include before-img-size(750, 960);

			@include breakpoint(large) {
				@include before-img-size(1920, 1000);
			}
		}

		img {
			object-position: bottom center;
		}
	}

	// Wave
	&__wave {
		--opacity: 0.3;

		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 80%;
		opacity: var(--opacity);

		&.pc {
			&::before {
				@include before-img-size(1920, 1000, true);
			}

			@at-root %absolute-img {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}

			canvas {
				@extend %absolute-img;
			}

			#{$root} {
				&__wave {
					&__svg {
						@extend %absolute-img;

						path {
							stroke-width: 0.3;
						}
					}
				}
			}
		}

		// 手機板(Img)
		&.mo {
			--opacity: 1;

			height: 100%;
			background-size: cover;

			@include breakpoint(medium only) {
				background-position: 80%;
			}
		}

		@include breakpoint(large) {
			filter: blur(1px);
		}
	}
}

.sec-index-banner {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-banner__wave', '.index-banner__img', '.index-banner__cont';

	&.js-ani {
		@include set-animate($animate-el, 0.5s);

		.index-banner__wave {
			opacity: 0;
			transform: scale(1.2);
		}

		// 移除由下方淡入動畫（避免客戶上滿版圖，動畫呈現怪異）
		.index-banner__img {
			transform: none;
		}

		&.is-animated {
			@include set-animated($animate-el);

			.index-banner__wave {
				opacity: var(--opacity);
				transform: scale(1);
			}
		}
	}
	/* stylelint-enable */
}

// --------------------------------------------------------

/* Product Category | 產品分類 */
.index-cate {
	$root: &;

	padding-top: calc(var(--padding-y) * #{(72 / 50)});

	@include breakpoint(medium) {
		padding-top: calc(var(--padding-y) * #{(160 / 50)});
	}

	&__list {
		margin: 0;
		list-style: none;
	}

	&__item {
		margin-bottom: rem-calc(20);
	}

	&__link {
		position: relative;
		display: block;
		box-shadow: 0 0 6.5px 0.5px rgba($black, 0.3);

		@include breakpoint(large) {
			box-shadow: 0 0 20px rgba($black, 0.3);
		}
	}

	&__text-box {
		padding: rem-calc(40 20);
		color: $white;
		background: rgba($primary-black, 0.5);
	}

	&__title {
		font-weight: $font-weight-m;
	}

	&__desc {
		margin-bottom: 0;
		word-break: break-word;
	}

	&__img-box {
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #ccc;

		&::before {
			display: none;
		}
	}

	@include breakpoint(small only) {
		&__desc {
			font-size: rem-calc(12);
		}
	}

	@include breakpoint(medium down) {
		&__img-box {
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&__text-box {
			text-shadow: 0 0 5px rgba($black, 0.5);
		}

		&__btn-box {
			display: none;
		}
	}

	@include breakpoint(large) {
		$tsd: 0.8s;

		@at-root %ani-dur {
			transition: all $tsd;
		}

		&__list {
			display: flex;
		}

		&__item {
			@extend %ani-dur;

			flex: 0 0 #{percentage(140 / 1440)};

			&:not(:last-child) {
				margin-right: rem-calc(20);
			}

			&.is-active {
				flex: 1 1 0;

				#{$root} {
					&__text-box {
						height: 20%;
						background: $primary-black;
					}

					&__title {
						max-width: 40%;
						word-break: break-word;
						line-height: 1.2;
					}

					&__desc,
					&__btn-box {
						width: auto;
						height: auto;
						opacity: 1;
						transition: opacity $tsd $tsd;
					}

					&__btn-box {
						.circle-box {
							&::before {
								border-width: 2px;
							}
						}
					}

					&__img-box {
						height: 80%;
					}
				}
			}

			&:not(.is-active) {
				#{$root} {
					&__text-box {
						--padding-x: 0;

						align-items: flex-end;
						justify-content: center;
					}

					&__title {
						--rotate: 90deg;
						--translateX: calc(-50% + #{1.4em / 2}); // 1.4 = line-height

						white-space: nowrap;
						transform: rotate(var(--rotate)) translateX(var(--translateX));
					}

					&__desc,
					&__btn-box {
						display: none;
					}
				}
			}
		}

		&__link {
			z-index: 1;
			overflow: hidden;
			height: 50vw;
			max-height: calc(100vh - #{rem-calc(75 + 30 + 30)});
			background: $primary-black;
		}

		&__text-box {
			@extend %ani-dur;

			--padding-x: #{rem-calc(20)};
			--padding-y: #{rem-calc(20)};

			position: absolute;
			bottom: 0;
			left: 0;
			display: flex;
			align-items: center;
			padding: var(--padding-y) var(--padding-x);
			padding-right: calc(var(--padding-x) - #{rem-calc(15)});
			width: 100%;
			height: 100%;
			background: rgba($primary-black, 0.5);
		}

		&__title {
			margin-bottom: 0;
		}

		@at-root %hide-content {
			overflow: hidden;
			width: 0;
			height: 0;
			opacity: 0;
		}

		&__desc {
			@extend %hide-content;

			flex: 1 1 0;
			margin-right: rem-calc(30 - 15); // 15: btn-box 的 padding
			margin-left: rem-calc(30);
		}

		&__btn-box {
			@extend %hide-content;

			padding: rem-calc(15); // 為了 Hover 上的動態呈現
		}

		&__img-box {
			@extend %ani-dur;
		}
	}

	@include breakpoint(xlarge) {
		&__item {
			&:not(.is-active) {
				#{$root} {
					&__text-box {
						--padding-y: #{rem-calc(80)};
					}
				}
			}
		}

		&__text-box {
			--padding-x: #{rem-calc(50)};
			--padding-y: #{rem-calc(35)};
		}
	}

	@include breakpoint(1440 + 100 * 2) {
		&__link,
		&__text-box {
			height: rem-calc(820);
		}
	}

	// [START] 特殊樣式：標題上、敘述下、按鈕右（因為空間不夠）
	@include breakpoint-between(large, xlarge) {
		&__item {
			max-height: 100%;

			&.is-active {
				#{$root} {
					&__text-box {
						flex-wrap: wrap;
						padding-right: rem-calc(90);
						height: auto;
						max-height: 50%;
					}

					&__title {
						width: 100%;
						max-width: 100%;
					}

					&__desc {
						flex: 0 0 100%;
						margin: 0;
						width: 100%;
					}
				}
			}
		}

		&__btn-box {
			position: absolute;
			right: 5px;
		}
	}

	@include breakpoint(xlarge only) {
		&__item {
			&.is-active {
				#{$root} {
					&__text-box {
						padding-right: rem-calc(90 + 30);
					}
				}
			}
		}

		&__btn-box {
			right: 35px;
		}
	}
	// [END] 特殊樣式：標題上、敘述下、按鈕右（因為空間不夠）

	@include breakpoint-hover {
		&__img {
			transition: transform 0.5s;
		}

		&__item {
			&.is-active {
				&:hover,
				&:focus,
				&:focus-within {
					#{$root} {
						&__text-box {
							color: $primary-black;
							background: $white;
						}

						&__img {
							transform: scale(1.1);
						}
					}

					.circle-box {
						@include btn-border-hover(true);
					}
				}
			}

			&:not(.is-active) {
				#{$root} {
					$tsd: 0.5s;

					&__title {
						--translateX: calc(-50% + #{1.4em / 2} + #{rem-calc(120 + 25)});
						// 1.4 = line-height; 120 = ::after width; 25 = ::after margin-left

						display: flex;
						align-items: center;
						margin: 0;
						white-space: nowrap;
						transition: transform $tsd;

						// Hover 裝飾線
						&::after {
							content: '';
							display: inline-block;
							margin-left: rem-calc(25);
							width: 120px;
							height: 1px;
							background: $white;
							box-shadow: 0 0 24px rgba($primary-black, 0.08);
							transform: scaleX(0);
							transform-origin: right center;
							transition: transform $tsd;
						}
					}
				}

				&:hover,
				&:focus,
				&:focus-within {
					#{$root} {
						&__title {
							--translateX: calc(-50% + #{1.4em / 2});

							&::after {
								transform: scaleX(1);
							}
						}
					}
				}
			}
		}
	}
}

.sec-index-cate {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-cate__item:nth-child(1) .index-cate__link', '.index-cate__item:nth-child(2) .index-cate__link',
		'.index-cate__item:nth-child(3) .index-cate__link', '.index-cate__item:nth-child(4) .index-cate__link';

	&.js-ani {
		@include set-animate($animate-el, 0.2s);

		&.is-animated {
			@include set-animated($animate-el);
		}
	}
	/* stylelint-enable */
}

// --------------------------------------------------------

// Category 與 Feature 中間的裝飾線
.index-cate-feature-deco-line {
	$rect-width: 345;
	$rect-height: 80;

	position: relative;

	@include breakpoint(small only) {
		margin-top: rem-calc(-30);
		margin-bottom: rem-calc(-20);
	}

	// 內容高
	&::before {
		@include before-img-size($rect-width, $rect-height, true);
	}

	&::after {
		--width: #{100 / $rect-width * sqrt(pow($rect-width, 2) + pow($rect-height, 2))}%; // 三角形斜邊長跟寬的比例

		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: calc(var(--width) / 2 * -1);
		width: var(--width);
		height: 1px;
		background: #a0a0a0;
		transform: rotate(-13deg);
	}

	@include breakpoint(medium) {
		$rect-width: 1440;
		$rect-height: 235;

		&::before {
			@include before-img-size($rect-width, $rect-height, true);
		}

		&::after {
			--width: #{100 / $rect-width * sqrt(pow($rect-width, 2) + pow($rect-height, 2))}%; // 三角形斜邊長跟寬的比例

			transform: rotate(-9deg);
		}
	}

	/* Animation */
	/* stylelint-disable */
	&.js-ani {
		transform: scale(0);
		transition: transform 0.5s;

		&.is-animated {
			transform: scale(1);
		}
	}
	/* stylelint-enable */
}

// --------------------------------------------------------

/* Featrue Product | 特色產品 */
.index-feature {
	padding-bottom: 0;

	// 區塊標題
	&__sec-title-box {
		position: relative;
	}

	&__sec-title {
		font-size: rem-calc(42);
		font-weight: $font-weight-b;
		line-height: 1.3;
		text-transform: uppercase;
		letter-spacing: ps-letter-spacing(100);
		color: #c9c9c9;

		@include breakpoint($xs-down) {
			font-size: rem-calc(32);
		}

		@include breakpoint(small only) {
			margin-bottom: em-calc(42 / 42);
		}

		@include breakpoint(medium) {
			font-size: rem-calc(60);
		}

		@include breakpoint(medium only) {
			margin-bottom: rem-calc(40);
		}

		@include breakpoint(large) {
			position: absolute;
			top: 0;
			left: 0;
			margin-left: em-calc(40 / 60 * -1);
			max-width: 8.6em;
			line-height: 2;
			transform: rotate(90deg) translateY(-100%);
			transform-origin: left top;
		}

		@include breakpoint-between(large, xlarge) {
			font-size: (45 / 1024 * 100vw);
		}
	}

	@at-root %feature-bg-deco {
		top: auto;
		bottom: 0;
		pointer-events: none;

		&.loaded-content {
			bottom: percentage(113 / 960 * -1);
		}

		&:not(.loaded-content) {
			&,
			img {
				width: 100%;
				height: 100%;
			}
		}

		@include breakpoint(medium down) {
			&.loaded-content {
				bottom: -6.3%;
			}

			img {
				transform: scale(1.2);
				transform-origin: center 90%;
			}
		}
	}

	// 木頭 - 背景
	&__wood-bg {
		@extend %feature-bg-deco;
	}

	// 木頭 - 前景
	&__wood-front {
		@extend %feature-bg-deco;

		z-index: 5;
	}

	&__info-box {
		text-align: right;
		word-break: break-word;

		@include breakpoint(medium down) {
			margin-bottom: rem-calc(6);
			padding-right: var(--padding-x);
			padding-left: var(--padding-x);
		}

		@include breakpoint(medium only) {
			text-align: left;
		}

		@include breakpoint(large) {
			position: absolute;
			z-index: 5;
			top: 0;
			right: 0;
			width: rem-calc(448);
		}

		@include breakpoint(large only) {
			margin-top: -0.3em;
		}

		@include breakpoint(xxlarge) {
			margin-top: 0;
			width: rem-calc(360);
		}
	}

	&__slogan {
		font-weight: $font-weight-b;
		letter-spacing: ps-letter-spacing(50);

		@include breakpoint(medium) {
			margin-bottom: em-calc((8 + 25) / 60);
			font-size: rem-calc(60);
		}

		@include breakpoint(medium only) {
			margin-top: 0;
			margin-bottom: rem-calc(10);
			line-height: 1.3;
		}

		@include breakpoint(large only) {
			margin-bottom: 0.3em;
		}
	}

	&__pd-name {
		margin-bottom: em-calc(20 / 12);
		font-size: rem-calc(12);
		font-weight: $font-weight-m;
		color: $primary-color;

		@include breakpoint(medium) {
			font-size: rem-calc(24);
		}

		@include breakpoint(medium only) {
			margin-bottom: rem-calc(19);
		}
	}

	&__img-box {
		&::before {
			@include before-img-size(1440, 800);
		}
	}

	// ======
	// Swiper
	.swiper-box {
		padding-bottom: percentage(62 / 1440); // 補足木頭剩餘部分的高

		@include breakpoint(medium down) {
			overflow: hidden;
			margin-right: calc(var(--padding-x) * -1);
			margin-left: calc(var(--padding-x) * -1);
			padding-bottom: percentage((62 + 40) / 1440);
		}

		@include breakpoint(small only) {
			--padding-x: #{rem-calc($gutter-s-half)};
		}
	}

	.swiper-slide {
		// Fade Not Active
		&:not(.swiper-slide-active) {
			opacity: 0 !important;
		}
	}

	.swiper-ctrl {
		@include breakpoint(medium) {
			position: absolute;
			z-index: 10;
			bottom: 22%;
			left: var(--padding-x);
			width: rem-calc(180);
		}

		@include breakpoint(medium only) {
			--x: 0%;
			--y: calc(-100% + 9px);

			bottom: 43.8vw;
			right: var(--padding-x);
			left: auto;
			transform: translate(var(--x), var(--y));
		}

		@include breakpoint(large) {
			left: 3px;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		--pos: var(--padding-x);

		&::after {
			font-family: 'icon'; // 被 Swiper 原本設定蓋過，原因不明
		}

		@include breakpoint(medium) {
			--pos: 0;

			margin-top: calc(0px - (var(--swiper-navigation-size) / 2) + #{em-calc(10 / 16)});
		}
	}

	.swiper-button-prev {
		left: var(--pos);
	}

	.swiper-button-next {
		right: var(--pos);
	}

	// Swiper - Pagination
	.swiper-pagination {
		position: static;
		font-size: rem-calc(36);
		color: #a0a0a0;

		@include breakpoint(small only) {
			display: none;
		}
	}

	.swiper-pagination-current {
		display: inline-block;
		min-width: (40 / 60 * 1em);
		font-size: percentage(60 / 36);
		color: $primary-color;
	}
}

.sec-index-feature {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-feature__sec-title-box', '.img-deco, .index-feature__img-box',
		'.index-feature__info-box, .swiper-ctrl';

	&.js-ani {
		@include set-animate($animate-el);

		.index-feature__info-box {
			transform: translateX(50px);
		}

		.index-feature__sec-title-box,
		.swiper-ctrl {
			transform: translateX(-50px);
		}

		&.is-animated {
			@include set-animated($animate-el);
		}

		@include breakpoint(medium only) {
			.swiper-ctrl {
				--x: -50px;

				transform: translate(var(--x), var(--y));
			}

			&.is-animated {
				.swiper-ctrl {
					--x: 0;

					transform: translate(var(--x), var(--y));
				}
			}
		}
	}
	/* stylelint-enable */
}

// --------------------------------------------------------

/* Blog / News | 新聞消息 */
.index-news {
	$root: &;

	z-index: 10;
	padding-top: calc(var(--padding-y) * #{(50 - 30) / 50} + #{rem-calc(50)});

	@include breakpoint(medium) {
		padding-top: rem-calc(64 + 100);
	}

	.bg-box {
		@include breakpoint(1921) {
			// -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) calc(31vw - 665px));
			// 1920: 下一卡背景圖寬
			// 600: 下一卡背景圖高
			// 600 / 1920 * 100vw = 下一卡背景圖寬高比，以100vw換算，高度多少vw
			// 665px = 下一卡內容高
			mask-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 1) calc(#{(600 / 1920 * 100vw)} - #{rem-calc(665)})
			);
		}

		&__bg {
			background-position: bottom;
		}
	}

	@at-root %news-sec-title {
		position: relative;
		z-index: 5;
		margin-bottom: em-calc((8 + 11) / 42);
		margin-left: ps-letter-spacing(300); // 為了置中
		font-size: rem-calc(42);
		font-weight: $font-weight-b;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: ps-letter-spacing(300);
		color: $white;
		text-shadow: 0 2px 6px rgba($black, 0.5);

		@include breakpoint(medium) {
			font-size: rem-calc(80);
			text-shadow: 0 5px 18px rgba($black, 0.2);
		}

		@include breakpoint(large) {
			margin-bottom: -0.6em; // light-height / 2，為了讓標題壓到內容
			font-size: rem-calc(112);
		}
	}

	// 區塊標題：Blog
	&__sec-title-box {
		position: relative;
	}

	&__sec-title {
		@extend %news-sec-title;

		// 標題文字改為 Border 版本後的裝飾文字
		&--text-border {
			@extend %news-sec-title;

			--stroke: #{$white};
			--stroke-width: 1px;

			position: absolute;
			z-index: -1;
			top: -0.1em;
			left: 0;
			margin-left: ps-letter-spacing(300 / 2);
			width: 100%;
			color: transparent;
			opacity: 0.4;
			pointer-events: none;
			-webkit-text-stroke: var(--stroke-width) var(--stroke);

			@include breakpoint(medium) {
				--stroke-width: 2px;
			}
		}
	}

	&__list {
		margin-bottom: rem-calc(15);
		list-style: none;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(50);
		}
	}

	// === 單項目 ===
	&__item {
		margin-bottom: rem-calc(20);
	}

	&__link {
		display: block;
		color: $white;
		background: $primary-black;
		box-shadow: 0 0 20px rgba($black, 0.3);
		transition: 0.3s;

		@include breakpoint(small only) {
			font-size: rem-calc(12);
			box-shadow: 0 0 7px 0.5px rgba($black, 0.3);
		}

		// 恢復 Foundation 設定
		&:hover {
			color: $white;
			background: $primary-black;
		}

		&:visited {
			#{$root} {
				&__title {
					color: $visited-white-color;
				}
			}
		}
	}

	// === 文 ===
	&__text-box {
		padding: rem-calc(20);

		@include breakpoint(medium) {
			padding: rem-calc(30);
		}

		&__cell {
			padding-right: rem-calc(10);
			padding-left: rem-calc(10);

			&.info {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}

			// 為了設置Border
			&.title {
				margin-top: rem-calc(20);
				padding-top: rem-calc(20);
				border-top: 1px solid currentColor;
			}
		}
	}

	// 新聞 - Tag
	&__tag {
		padding: rem-calc(2.2 15);
		border: 1px solid $border-color;
		border-radius: 99px;
	}

	// 新聞 - 標題
	&__title {
		overflow: hidden;
		font-size: rem-calc(14);
		font-weight: $font-weight-m;
		text-overflow: ellipsis;
		white-space: nowrap;

		@include breakpoint(medium) {
			font-size: rem-calc(20);
		}
	}

	&__img-box {
		background: #eee;

		@include breakpoint(medium down) {
			&::before {
				@include before-img-size(345, 240);
			}
		}
	}

	// Hover
	@include breakpoint-hover {
		$tsd: 0.5s;

		@at-root %transition-transform {
			transition: transform $tsd;
		}

		&__text-box {
			position: relative;
			z-index: 5;
			overflow: hidden;
		}

		&__img {
			@extend %transition-transform;
		}

		// Hover Item
		&__link {
			position: relative;
			overflow: hidden;
			transition: $tsd;

			&:hover,
			&:focus {
				color: $primary-black;

				#{$root} {
					&__text-box {
						background: $white;
					}

					&__img {
						transform: scale(1.1);
					}
				}

				&:visited {
					#{$root} {
						&__title {
							color: $visited-color;
						}
					}
				}
			}
		}
	}
	// End Hover
} // END .index-news

@keyframes spin {
	0% {
		transform: rotate(0deg) translate3d(0, 0, 0);
		will-change: transform;
	}

	100% {
		transform: rotate(360deg) translate3d(0, 0, 0);
		will-change: transform;
	}
}

.sec-index-news {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-news__sec-title-box', '.index-news__item:nth-child(1)', '.index-news__item:nth-child(2)',
		'.index-news__item:nth-child(3)', '.index-news__btn-box';

	&.js-ani {
		@include set-animate($animate-el);

		&.is-animated {
			@include set-animated($animate-el);
		}
	}
	/* stylelint-enable */
}

// --------------------------------------------------------

/* Brand | 品牌理念呈現 & 更多展示 */
.index-brand {
	position: relative;
	padding-top: calc(var(--padding-y) * #{((50 - 10) / 50)});

	@include breakpoint(small only) {
		padding-top: rem-calc(70);
	}

	// [START] 為了調整背景花紋顯示
	.bg-box {
		z-index: 2;
	}

	.index-brand__bg {
		z-index: 5;
	}

	> .grid-container {
		position: relative;
		z-index: 6;
	}
	// [END] 為了調整背景花紋顯示

	@include breakpoint(medium) {
		padding-top: calc(var(--padding-y) * #{((140 + 50) / 50)});
	}

	@include breakpoint(large) {
		padding-bottom: calc(var(--padding-y) * #{((89 + 50) / 50)});
	}

	// 背景（要可以上稿）
	&__bg {
		position: absolute;
		z-index: -1;
		bottom: 0;
		left: 0;
		width: 100%;
		background: no-repeat 20% bottom / 210vw;
		mask-image: linear-gradient(to top, $black calc(100% - 100px), transparent 100%);

		// Background Size
		&::before {
			@include before-img-size(375, 270);
		}

		@include breakpoint(medium down) {
			background-size: cover;
		}

		@include breakpoint(large) {
			background-size: 1920px;

			&::before {
				@include before-img-size(1920, 600);
			}
		}

		@include breakpoint-between(large, 1920) {
			min-height: 600px;
		}

		@include breakpoint(1920) {
			background-size: 100%;
		}
	}

	&__cont {
		justify-content: center;

		@include breakpoint(small only) {
			padding-bottom: 40%;
		}

		@include breakpoint(medium only) {
			padding-bottom: 50%;
		}

		@include breakpoint(large) {
			justify-content: flex-end;
			padding-right: percentage(1 / 12);
		}
	}

	&__text-box {
		position: relative;
		z-index: 1;

		&::before {
			// --bg-color: #{hex-to-rgb($bg-color)};
			--bg-color: #{$bg-color};

			content: '';
			position: absolute;
			z-index: -1;
			top: 50%;
			left: 50%;
			width: 300%;
			height: 300%;
			background: $bg-color;
			background: radial-gradient(var(--bg-color) 0%, rgba(255, 255, 255, 0) 70.71068%);
			transform: translate(-50%, -50%);
			pointer-events: none;
		}

		@include breakpoint(small only) {
			&.cell {
				width: percentage(285 / 375);
			}
		}

		@include breakpoint(medium) {
			min-width: rem-calc(340);
		}
	}

	&__brand-box {
		margin-bottom: rem-calc(15);
		max-width: percentage(167 / 375) / 1% * 1vw;

		@include breakpoint(medium) {
			margin-bottom: rem-calc(30);
			max-width: rem-calc(340);
		}
	}

	&__desc {
		margin-bottom: rem-calc(40);

		@include breakpoint(small only) {
			margin-bottom: rem-calc(15);
			font-size: rem-calc(12);
		}
	}
}

// 更多展示
// .svc-list {}
// 移動至_common

.sec-index-brand {
	/* Animation */
	/* stylelint-disable */
	$animate-el: '.index-brand__bg',
		// 假的暫延遲空間
		'.index-brand__cont';

	&.js-ani {
		@include set-animate($animate-el);

		.index-brand__bg {
			opacity: 0;
			transition: opacity 0.3s;
		}

		&.is-animated {
			@include set-animated($animate-el);

			.index-brand__bg {
				opacity: 1;
			}
		}
	}
	/* stylelint-enable */
}

// --------------------------------------------------------

// 廣告（只有首頁才有）
.reveal-event {
	padding: 0;

	@at-root :root {
		--screen-height: 1080px; // 螢幕高度
	}

	@include breakpoint(medium down) {
		left: rem-calc(15);
		margin: 0;
		width: calc(100% - #{rem-calc(15 * 2)});
		height: auto;
		min-height: 0;
		max-height: calc(100% - #{rem-calc(15 * 2)});
	}

	@include breakpoint(large) {
		--cont-height: #{rem-calc(370)};

		padding: rem-calc(20);
		padding-bottom: 0;
		width: 100%;
		max-width: rem-calc(960);
		min-width: rem-calc(600);
		max-height: calc(100% - 10px);
		overflow-y: auto;
	}

	&__cont {
		--padding-x: #{rem-calc(20)};
		--padding-y: #{rem-calc(20)};

		padding: var(--padding-y) var(--padding-x);

		@include breakpoint(small only) {
			padding-bottom: rem-calc(50);
		}

		@include breakpoint(medium) {
			--padding-y: #{rem-calc(50)};
		}
	}

	&__img-box {
		@include breakpoint(large) {
			max-height: calc(var(--screen-height) - 100px - var(--cont-height) - #{rem-calc(20)});
			min-height: rem-calc(200);
		}

		&::before {
			@include before-img-size(960, 540);
		}

		// &::after {
		// 	content: '';
		// 	position: absolute;
		// 	z-index: -1;
		// 	left: 0;
		// 	top: 0;
		// 	width: 100%;
		// 	height: 100%;
		// 	background: url('../images/logo.svg') no-repeat center center / 100px;
		// 	opacity: 0.3;
		// 	transition: opacity 1s;
		// }

		// &.loaded-content {
		// 	&::after {
		// 		opacity: 0;
		// 	}
		// }
	}

	&__img {
		&.lazyload {
			opacity: 0;
			transition: opacity 1s;

			&.loaded {
				opacity: 1;
			}
		}
	}

	&__text-box {
		word-break: break-word;
	}
}

// --------------------------------------------------------

/* i18n */
@import 'lang/index-zh';
