// Product Category | 產品分類
// 產品分類，非Active呈現直排
%index-category-title {
	.index-cate {
		$root: '.index-cate';

		@include breakpoint(large) {
			&__item {
				&:not(.is-active) {
					#{$root} {
						&__title {
							width: 1em;
							white-space: inherit;
							transform: none;
						}
					}
				}
			}
		}

		@include breakpoint-hover {
			&__item {
				&:not(.is-active) {
					#{$root} {
						&__title {
							position: relative;

							&::after {
								position: absolute;
								top: 100%;
								margin: 18px;
								width: 1px;
								height: 120px;
								transform: scaleY(0);
								transform-origin: center bottom;
							}
						}
					}

					&:hover,
					&:focus,
					&:focus-within {
						#{$root} {
							&__title {
								transform: translateY(-120px);

								&::after {
									transform: scaleY(1);
								}
							}
						}
					}
				}
			}
		}
		// [END] breakpoint-hover
	}
}

// --------------------------------------------------------

// Featrue Product | 特色產品
// 特色產品，呈現直排
%index-feature-sec-title {
	@include breakpoint(large) {
		.index-feature {
			&__sec-title {
				margin-left: 0;
				margin-left: em-calc(2 / 60 * -1);
				transform: none;
				width: 1em;
				line-height: 1.2;
			}
		}
	}
}

// --------------------------------------------------------

// 語言設定
html[lang^='zh'] {
	@extend %index-category-title;
	@extend %index-feature-sec-title;
}
