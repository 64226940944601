// 暗色模式（為了符合首頁上稿可以更改亮暗色，目前只生成於index.scss裡）

%hd-color-black {
	--hd-color: #{$black};
	--hd-color-rgb: #{hex-to-rgb($black)};
	--page-nav-color: #7f7f7f;
}

%hd-color-white {
	--hd-color: #{$white};
	--hd-color-rgb: #{hex-to-rgb($white)};
	--page-nav-color: #{$white};
}

:root {
	@extend %hd-color-black;
}

@mixin hd-dark-mode {
	.hd-dark-mode {
		@content;
	}
}

@mixin hd-dark-mode-not-collapse {
	@include hd-dark-mode {
		.hd {
			&:not(.is-collapse) {
				@content;
			}
		}
	}
}

@mixin hd-dark-mode-not-collapse-not-open {
	@include hd-dark-mode {
		.hd {
			&:not(.is-collapse) {
				&:not(.is-menu-open) {
					@content;
				}
			}
		}
	}
}

@mixin hd-is-menu-open {
	.hd {
		&.is-menu-open {
			@content;
		}
	}
}

@include hd-dark-mode {
	@extend %hd-color-white;
}

// -------------------------------------------------------

// Header
// Hamburger Menu Button
@include breakpoint(large) {
	@include hd-dark-mode-not-collapse-not-open {
		.hd-ham {
			&__path {
				stroke: var(--hd-color);
			}

			@include breakpoint-hover {
				&:hover,
				&:focus {
					background: rgba(var(--hd-color-rgb), 0.2);
				}
			}
		}

		// Header Logo
		.hd-logo {
			.brand-logo {
				&__logo,
				&__year {
					filter: invert(1);
				}
			}

			&__line {
				background-color: var(--hd-color);
			}
		}

		// Menu Right button
		.hd {
			&__btn {
				color: var(--hd-color);

				@include breakpoint-hover {
					&:hover,
					&:focus {
						color: var(--hd-color);
						background: rgba(var(--hd-color-rgb), 0.2);
					}
				}
			}
		}
	}
}

// -------------------------------------------------------

// Page
// Page Navigation Menu
%page-nav-dark-mode {
	.page-nav {
		&__link {
			&:not(:hover) {
				&:not(:focus) {
					color: var(--page-nav-color);
				}
			}
		}
	}
}

.page-nav {
	&:not(.is-fixed) {
		&:not(.page-nav--fade-out) {
			@extend %page-nav-dark-mode;
		}
	}
}

.page-nav--fade-out {
	@extend %page-nav-dark-mode;
}
