// 說明 (完整說明見) src/sass/style.sass
//	* 此檔案包含：通用於所有SCSS/SASS變數檔案
//      - 變數、mixin、function
//      - 所有單元都建議須要載入之樣式
//      - 避免寫實際的樣式於該檔案內（包含檔案內 @import 之其它CSS檔案）

@charset 'utf-8';
// --------------------------------------------------------

// Vendor

// Icon Font
@import '../vendor/font/icons'; // 載入Icon Font 變數
@import '../foundation/foundation';

// Function
@import '../vendor/trig'; // 三角函數
@import '../vendor/photoshop-function'; // Photoshop 效果轉換
@import '../vendor/function'; // 常用的 Function

// --------------------------------------------------------

// Variable
$primary-color: $primary-color; // Foundation 主顏色變數
$primary-black: $black; // 全站主要使用的黑（避免哪天客戶調整CI)

$gray-light1: #e8e8e8; // black opacity 0.5
$gray-dark1: #7f7f7f; // black opacity 0.5
$gray-dark2: #5e5e5e; // black opacity 0.5

$border-color: #b2b2b2;

$disabled-color: $border-color;

$bg-color: #f0f0f0;
$bg-gradient: linear-gradient(to right, #f1f1f1 0%, #f6f6f6 100%);

$hover-bg: rgba($black, 0.05);
$hover-bg-solid: #f2f2f2; // 以白為背景的 $hover-bg 實色

$visited-color: lighten($black, 20%);
$visited-white-color: darken($white, 20%);

// Status
$status-info: map-get($foundation-palette, secondary); // #767676
$status-success: map-get($foundation-palette, success); // #9dc93a
// $status-error: map-get($foundation-palette, alert); // #f00
$status-error: $primary-color; // #f00
$status-warning: map-get($foundation-palette, warning); // #ffae00

// Box Shadow
$box-shadow-primary-m: 0 2px 7px rgba($black, 0.15); // Mobile
$box-shadow-primary: 0 0 18.4px 1.6px rgba($black, 0.1);
$box-shadow-primary-3: 0 0 18.4px 1.6px rgba($black, 0.3);
$box-shadow-primary-hover: 0 0 30px 5px rgba($black, 0.1);
$box-shadow-primary-hover-3: 0 0 30px 5px rgba($black, 0.3);
$box-shadow-panel: 0 2px 15px 0 rgba($black, 0.1); // Header Menu box-shadow, Panel
// $box-shadow-primary: 0 0 24px rgba($black, 0.08); // Bullet

// ==========

// Font-weight
$font-weight-m: 500; // Medium
$font-weight-b: 600; // SemiBold

// ==========

// Foundation Variable
// Gutter
$gutter-s: map-get($grid-margin-gutters, small);
$gutter-s-half: $gutter-s / 2;
$gutter-m: map-get($grid-margin-gutters, medium);
$gutter-m-half: $gutter-m / 2;

$xs: 375;
$xs-down: ($xs - 1) down;

// ==========

$logo-tsd: 0.5s; // Logo 變小時間
$hd-tsd: 0.5s; // Header is-collapse 時間

// --------------------------------------------------------

// <select>

// Select Arrow
@function select-arrow-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.107 53.616"%3E%3Cpolyline fill="none" stroke="%23#{$color-normal}" stroke-width="7" stroke-linecap="round" stroke-linejoin="round" points="3.5 3.501 57.553 50.116 111.607 3.501"/%3E%3C/svg%3E';
	@return $build-svg;
}
@function select-arrow-reverse-builder($color: $body-font-color) {
	$color-normal: str-replace('' + $color, '#', '');
	$build-svg: 'data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.11 53.62"%3E%3Cpath fill="none" stroke="%23#{$color-normal}" stroke-linecap="round" stroke-linejoin="round" stroke-width="7" d="M111.61 50.12L57.55 3.5 3.5 50.12"/%3E%3C/svg%3E';
	@return $build-svg;
}
$select-arrow: select-arrow-builder();
$select-arrow-white: select-arrow-builder($white);
$select-arrow-reverse: select-arrow-reverse-builder();
$select-arrow-reverse-white: select-arrow-reverse-builder($white);

// Select Style
@mixin select-style($use-white: false) {
	padding-right: rem-calc(40);
	text-overflow: ellipsis;
	cursor: pointer;

	// Arrow
	background: no-repeat right 12px center / 16px;

	@if $use-white {
		background-image: url('#{$select-arrow-white}');
	} @else {
		background-image: url('#{$select-arrow}');
	}

	@include breakpoint(medium) {
		padding-right: rem-calc(55);
		background-position: right 19px center;
	}
}

// --------------------------------------------------------

// Function

// 計算vw
@function vw-calc($num) {
	@return #{$num * 100}vw;
}

// 計算vh
@function vh-calc($num) {
	@return #{$num * 100}vh;
}

// --------------------------------------------------------

// Fluid Typography | 流體排版
// Ref: https://css-tricks.com/snippets/css/fluid-typography/

$min-width: 320px;
$max-width: 1200px;
$min-font: 16px;
$max-font: 24px;

@mixin fluid-type($min-vw: $min-width, $max-vw: $max-width, $min-font-size: $min-font, $max-font-size: $max-font) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(
					#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
						((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
				);
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

// --------------------------------------------------------

// Mixin

// Flex
@mixin fc($justify, $align) {
	display: flex;

	@if $justify {
		justify-content: $justify;
	}

	@if $align {
		align-items: $align;
	}
}

// 遵循BEM的規則，於class增加tag（僅限於單層方式，多層不適用）
// 可使用（單層）： .component__link => a.component__link
// 不適用（多層）： .component .component__link => .component a.component__link
@mixin bem-tag($tag: 'a') {
	$current: &;

	@at-root #{$tag}#{$current} {
		@content;
	}
}

// 使用 ::before 的 padding-top 撐高度
@mixin before-img-size($width: 1, $height: 1, $build-other: true) {
	@if $build-other {
		content: '';
		display: block;
	}

	padding-top: percentage($height / $width);
}

// ---------------------------------------- [START] Media Query 相關
// Hover Media Query (使用 Foundation Mixin)
$project-has-ie: false; // 專案是否有含 IE => 有 IE 就不能使用 @media hover

@mixin breakpoint-hover($hover-enable: true) {
	// 有 IE 專案
	@if $project-has-ie {
		@if $hover-enable {
			@include breakpoint(xlarge) {
				@content;
			}
		} @else {
			@include breakpoint(large down) {
				@content;
			}
		}
	}

	// 無 IE 專案
	@else {
		@if $hover-enable == true {
			@media (hover: hover) and #{breakpoint(xlarge)} {
				@content;
			}
		} @else if $hover-enable == 'only' {
			// 只有Hover的狀態，沒有寬度限制
			@media (hover: hover) {
				@content;
			}
		} @else {
			@media (hover: none) and #{breakpoint(large down)} {
				@content;
			}
		}
	}
}

// Breakpoint Between (使用 Foundation Mixin)
@mixin breakpoint-between($start, $end) {
	@media screen and #{breakpoint($start)} and #{breakpoint($end down)} {
		@content;
	}
}

@mixin mode($mode: dark) {
	@if $mode == 'light' {
		@media (prefers-color-scheme: light) {
			@content;
		}
	} @else {
		@media (prefers-color-scheme: dark) {
			@content;
		}
	}
}

// ---------------------------------------- Hack

@mixin webp($enable) {
	$el: &;

	@if ($enable) {
		@at-root .webp {
			#{$el} {
				@content;
			}
		}
	} @else {
		@at-root .no-webp {
			#{$el} {
				@content;
			}
		}
	}
}

// ---------------------------------------- [END] Media Query 相關

// ---------------------------------------- [START] Custom Scroll Bar
$scrollbar-thumb-color: $primary-color; // 操作顏色
$scrollbar-track-color: #eee; // 滾軸背景
$scrollbar-width: 6px; // 寬度(瀏覽器預設為15px)
$scrollbar-border-radius: 6px; // 圓角，Firefox不支援

@mixin custom-scroll-bar($thumb-color: $scrollbar-thumb-color, $track-color: $scrollbar-track-color) {
	// For Chrome, Opera, Safari, Android, iOS
	&::-webkit-scrollbar {
		width: $scrollbar-width;
		height: $scrollbar-width;
	}

	&::-webkit-scrollbar-track {
		background: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		background: $thumb-color;
		border-radius: $scrollbar-border-radius;

		&:hover {
			background: darken($thumb-color, 10%);
		}
	}

	@if & {
		// For Firefox (版本必須64+)
		// 如果是整頁<body>要使用，必須將Class增加於<html>標籤上
		// Ref: https://stackoverflow.com/a/6165489/11240898
		// 顏色：操作顏色(Thumb) 滾軸背景(Track)
		scrollbar-color: $thumb-color $track-color;
		// 寬度只有 auto | thin | none 選項
		// auto為15px，thin為6px，none為不顯示
		scrollbar-width: thin;
	}
}

// ---------------------------------------- [END] Custom Scroll Bar

// ---------------------------------------- [START] No Selection | 無法反選
// No Selection | 無法反選
// https://stackoverflow.com/a/4407335/11240898
@mixin selection-none {
	-webkit-touch-callout: none; // iOS Safari
	-webkit-user-select: none; // Safari
	-khtml-user-select: none; // Konqueror HTML
	-moz-user-select: none; // Firefox
	-ms-user-select: none; // Internet Explorer/Edge
	user-select: none; // Non-prefixed version, currently, supported by Chrome and Opera
}
// ---------------------------------------- [END] No Selection | 無法反選

// ---------------------------------------- [START] Toggle Show Style
// Toggle Show Style
// https://stackoverflow.com/a/30531678
$d-opacity: 0.25s;
$d-disappear: 0.5s;
/// @param {string} $switch - 開關class
/// @param {string} $targetEl - 要縮起展開的物件（通常為<a> ）
// 放在要加的 "is-open"(開關Class) class 的物件上
// @include toggle-menu-item('開關Class', '內層展開收闔物件，通常為<a>')
@mixin toggle-menu-item($switch, $targetEl) {
	// Hide
	&:not(#{$switch}) {
		#{$targetEl} {
			margin: 0;
			padding-top: 0;
			padding-bottom: 0;
			font-size: 0;
			line-height: 0;
			color: transparent;
			border-width: 0;
			-webkit-speak: none;
			opacity: 0;
			transition: opacity $d-opacity, font-size $d-disappear $d-opacity, line-height $d-disappear $d-opacity,
				margin $d-disappear $d-opacity, padding $d-disappear $d-opacity, color $d-opacity;
		}
	}
	// Show
	&#{$switch} {
		#{$targetEl} {
			transition: font-size $d-opacity, margin $d-opacity, padding $d-opacity, line-height $d-opacity,
				color $d-opacity, opacity $d-disappear $d-opacity;
		}
	}
}
// ---------------------------------------- [END] Toggle Show Style

// ---------------------------------------- [START] Animation Set
@mixin set-animate($animateEl, $delay: 0.3s) {
	@each $class in $animateEl {
		$i: index($animateEl, $class);
		$i: $i;

		#{$class} {
			opacity: 0;
			transform: translateY(50px);
			transition-duration: 1s;
			transition-property: opacity, transform;
			transition-delay: #{$i * $delay};
		}
	}
}

@mixin set-animated($animateEl) {
	@each $class in $animateEl {
		#{$class} {
			opacity: 1;
			transform: none;
		}
	}
}

@mixin set-animate-flow($animateEl) {
	&.js-ani {
		@include set-animate($animateEl);

		&.is-animated {
			@include set-animated($animateEl);
		}
	}
}

// ---------------------------------------- [END] Animation Set

// --------------------------------------------------------

// Project Custom

// ---------------------------------------- [START] Arrow (Function)
@function ani-arrow($dir: right, $tsd: 2s) {
	@return arrow-opacity $tsd infinite, arrow-to-#{$dir} $tsd infinite;
}
// ---------------------------------------- [END] Arrow (Function)

// ---------------------------------------- [START] Button Border Hover
// 具有Border & Border 間隙的物件（按鈕、圓型按鈕） - Hover Style
// ★★★外層是 .circle-box
// circle-box, button
@mixin btn-border-hover($build-icon: false, $color: $white, $bg-color: $black) {
	--space-expand: #{rem-calc(5)};
	--color: #{$color};
	--bg-color: #{$bg-color};
	// color: $white;

	&::after {
		animation: wave 4s 1s infinite;
	}

	// 生成 Icon 相關
	@if $build-icon {
		// ======
		// [Icon] Arrow
		.be-icon-arrow-to-right {
			animation: ani-arrow(right);
		}
	}
}
// ---------------------------------------- [END] Button Border Hover
